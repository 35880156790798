import {ApplicationRef, Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {UserTheme} from '../entities/user/user-preferences.entity';

@Injectable({providedIn: 'root'})
export class ThemingService {
  darkTheme = new BehaviorSubject(false);
  userTheme = UserTheme.SYSTEM_DEFAULT;
  isDarkTheme = false;

  constructor(private ref: ApplicationRef) {
    this.setUserTheme(this.userTheme);

    // Watch for changes of the preference
    try {
      window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => this.colorSchemeChanged(event));
    } catch {}
  }

  private colorSchemeChanged(event: MediaQueryListEvent): void {
    const isDarkTheme = this.userTheme === UserTheme.DARK || (this.userTheme === UserTheme.SYSTEM_DEFAULT && event.matches);
    this.setTheme(isDarkTheme);
  }

  private setTheme(isDarkTheme: boolean): void {
    if (isDarkTheme !== this.isDarkTheme) {
      this.isDarkTheme = isDarkTheme;
      this.darkTheme.next(this.isDarkTheme);

      // Trigger refresh of UI
      this.ref.tick();
    }
  }

  setUserTheme(userTheme: UserTheme): void {
    this.userTheme = userTheme;
    const isDarkTheme =
      this.userTheme === UserTheme.DARK ||
      (this.userTheme === UserTheme.SYSTEM_DEFAULT && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches);
    this.setTheme(isDarkTheme);
  }
}
