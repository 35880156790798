import {Injectable} from '@angular/core';

import * as Apollo from 'apollo-angular';
import {gql} from 'apollo-angular';
import * as Types from 'types';

export type PanelInfoSubscriptionVariables = Types.Exact<{[key: string]: never}>;

export type PanelInfoSubscription = {
  __typename?: 'Subscription';
  panelInfo?: {__typename?: 'PanelStatusInfoTO'; panelStatus: Types.PanelStatus; panelErrors: Array<Types.PanelError | null>} | null;
};

export const PanelInfoDocument = gql`
  subscription panelInfo {
    panelInfo {
      panelStatus
      panelErrors
    }
  }
`;

@Injectable({
  providedIn: 'root'
})
export class PanelInfoGQL extends Apollo.Subscription<PanelInfoSubscription, PanelInfoSubscriptionVariables> {
  document = PanelInfoDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
