export enum ProtocolTypes {
  BACNET = 0,
  MODBUS_TCP = 1,
  M_BUS = 2,
  IO = 3,
  TABS = 4,
  MODEM = 5,
  MODBUS_RTU = 6,
  OPC_UA = 7,
  ESPA = 9,
  CUSTOM = 100,
  LEGACY_IO = 101,
  OZW = 102,
  ALARM = 103,
  IOT = 104,
  WEATHER = 105,
  WAGO_MQTT = 106
}
